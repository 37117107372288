<template>
  <div class="list-user">
    <!-- <OutletSelect @change="onSelectOutlet"></OutletSelect> -->
    <KTPortlet class="mt-3" title="Danh sách nhân viên">
      <template v-slot:body>
        <!-- Search input -->
        <div class="row">
          <div class="col-6">
            <SearchInput
              pHolder="Tìm kiếm tên NV..."
              @search="onSearch"
            ></SearchInput>
          </div>
          <div class="col-6 search-outlet">
            <OutletSelect
              @change="onSelectOutlet"
              :hidenTitle="true"
            ></OutletSelect>
          </div>
        </div>

        <!-- Table -->
        <div class="mt-4 table-user table-custom">
          <b-table
            :items="users"
            :current-page="currentPage"
            responsive="sm"
            :fields="fields"
            :sticky-header="true"
            head-variant="light"
            v-if="users.length"
          >
            <template v-slot:head(data)>Tên nhân viên</template>
            <template v-slot:cell(data)="data">
              <div
                class="btn-sm"
                v-b-toggle="data.value.phoneNumber"
                @click="data.toggleDetails"
              >
                <span class="icon mr-2">
                  <i
                    :class="
                      data.detailsShowing ? 'flaticon2-back' : 'flaticon2-down'
                    "
                  ></i>
                </span>
                <span>{{ data.value.name }}</span>
              </div>
            </template>
            <template v-slot:head(status)>Trạng thái</template>
            <template v-slot:cell(status)="status">
              <span>
                <b-badge
                  v-if="status.value === 1"
                  variant="success"
                  class="btn-status"
                  >Kích hoạt</b-badge
                >
                <b-badge
                  v-if="status.value === 2"
                  variant="danger"
                  class="btn-status"
                  >Chưa KH</b-badge
                >
              </span>
            </template>
            <template v-slot:head(_id)="_id">
              <span :id="_id.value"></span>
            </template>
            <template v-slot:cell(_id)="data">
              <div class="text-right edit-user">
                <i
                  class="flaticon2-information text-info"
                  @click="onViewUserReport(data.value, data.item)"
                ></i>
              </div>
            </template>
          </b-table>
           <div v-else class="d-flex justify-content-center">
            <section v-if="loading" class="loading align-items-center">
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
            </section>
            <div class="loading align-items-center" v-else>
              <div class="text-center">
                <h1><i class="flaticon2-open-box"></i></h1>
                <h5>Không tìm thấy dữ liệu thích hợp</h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 d-flex justify-content-center"
          v-if="totalItem > itemPage"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItem"
            :per-page="itemPage"
            id="promo-table"
          ></b-pagination>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/report/outlet.scss";
@import "./User.scss";
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import OutletSelect from "@/components/outlet/Select.vue";
import SearchInput from "@/components/SearchInput.vue";
import Loader from "@/common/loader";
import { getUsers } from "@/api/user";

export default {
  name: "UserList",
  components: {
    KTPortlet,
    OutletSelect,
    SearchInput
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      selectedStaff: "",
      isLoading: false,
      outletIdCurrent: "",
      fields: [
        {
          key: "name",
          label: "Tên"
        },
        {
          key: "status",
          label: "Trạng thái"
        },
        {
          key: "_id",
          label: ""
        }
      ],
      totalItem: 0,
      itemPage: 10,
      loading: false,
      users: [],
      searchText: '',
    };
  },

  watch: {
    currentPage(val) {
      this.getDataUsers();
    }
  },

  methods: {
    onSelectOutlet(event) {
      const params = {
        outlet: event
      };
      this.outletIdCurrent = event;
      this.getDataUsers()
    },

    getDataUsers() {
      this.users = [];
      this.loading = true;
      return getUsers({
        page: this.currentPage,
        outletId: this.outletIdCurrent,
        text: this.searchText
      }).then(res => {
          this.users = res.data.users;
          this.totalItem = res.data.total;
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false;
        });
    },

    onViewUserReport(id, data) {
      if (this.isLoading === false) {
        this.isLoading = true;
        this.$router.push({
          name: "user-report",
          params: {
            id,
            outletId: this.outletIdCurrent,
            userName: data.name
          }
        });
      }
    },
    onSearch(event) {
      this.searchText = event;
      this.getDataUsers();
    }
  },
  mounted() {
    this.$store.dispatch("updateListUsers");
    this.$store.dispatch("titleBreadcrumb", "Báo cáo theo nhân viên");
  },
};
</script>
